import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import img1 from "../assets/img/img2.jpeg";
import img2 from "../assets/img/cats.jpg";

class Inicio extends React.Component {
  render() {
    return (
      <>
        <Navbar />

        <div id="about" className="nano" style={{ zIndex: "2" }}>
          {/* <div className="nano-content"> */}
          <div className="col-12 text-center" style={{ paddingTop: "20vw" }}>
            <h1
              className="align-self-center text-light titulo"
              style={{
                paddingBottom: "15vw",
                fontFamily: ["Playfair Display", "serif"],
              }}
            >
              PIERRE ILUSIONISTA
            </h1>
          </div>

          <div className="col-12">
            <div
              className="row justify-content-center bio"
              style={{ height: "10vw" }}
            ></div>
            <div className="row justify-content-center bg-black">
              <h1 className="text-light">MAGIA MODERNA</h1>
            </div>
            <div className="row justify-content-center bg-black">
              <p className="text-light text-margin-sides text-center">
                Pierre es un Mago - Ilusionista campeón y creador de magia
                original.
                <br />
                En el 2018, sus creaciones lo llevaron a obtener el prestigioso
                Premio Richiardi "Richiardi Awards", convirtiéndolo en el primer
                Mago - Mentalista en obtener el primer lugar en la categoría de
                Invención.
                <br />
                Actualmente sus creaciones son realizadas por diferentes magos
                alrededor del mundo.
              </p>
            </div>
          </div>

          <div className="bg-black">
            <br />
            <br />
            <div
              className="row col-12 bg-black text-light"
              style={{ margin: 0 }}
            >
              <div className="col-lg-6 col-md-12 text-center text-lg-right">
                <h1>MAGIA EN TEATRO</h1>
                <p>
                  Una exquisita y cuidadosa puesta en escena, con un alto nivel
                  de calidad, pretende borrar la delgada linea que divide la
                  ilusión de la realidad: mentalismo, telepatía, psicoquinesis y
                  telequinesis son los componentes de este impresionante
                  expectáculo. <br />
                  "Experimento Paranormal", el unipersonal presentado por Pierre
                  Ilusionista, es un experiencia íntima a travez de la cual el
                  público tiene un acercamiento a lo desconocido; logrando que
                  vivan la magia en su estado más puro.
                </p>
              </div>
              <div className="col-lg-6 col-md-12 text-center">
                {/* <img
                    className="align-self-center"
                    src={img1}
                    width="400"
                    height="auto"
                    style={{ maxWidth: "100%" }}
                  /> */}
              </div>
            </div>
            <br />
            <br />
            <div className="col-12 bg-black text-light" style={{ margin: 0 }}>
              <div className="col-lg-6 col-md-12 text-center text-lg-left float-right">
                <h1>EVENTOS CORPORATIVOS</h1>
                <p>
                  En este unipersonal, Pierre reune los mejores actos de magia e
                  ilusionismo que ha desarrollado a lo largo de su carrera,
                  entre ellos un acto que lo consagró como Campeón de Magia,
                  manteniendo a los presentes al filo de sus asientos y
                  cautivando a diferentes audiencias a nivel nacional e
                  internacional
                </p>
              </div>
              <div className="col-lg-6 col-md-12 text-center">
                <img
                  className="align-self-center"
                  src={img2}
                  width="600"
                  height="auto"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            </div>
            <br />
            <br />
          </div>

          <Footer />
          {/* </div> */}
        </div>
      </>
    );
  }
}

export default Inicio;
