import React from "react";

export default function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <footer
      className="page-footer font-small teal pt-4 bg-black text-light"
      style={{ position: "relative" }}
    >
      <div className="container-fluid text-center text-md-left">
        <div className="row justify-content-center">
          <span
            className="align-self-center text-light titulo"
            style={{
              fontFamily: ["Playfair Display", "serif"],
              fontSize: "30px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            PIERRE ILUSIONISTA
          </span>
        </div>
        <div className="row justify-content-center">
          <strong className="text-light">Contacto</strong>
        </div>
        <div className="row justify-content-center">
          <span>pierrevelarde2@gmail.com</span>
        </div>

        <div className="row justify-content-center">
          <a
            href="https://www.instagram.com/pierreilusionista/"
            className="btn btn-info btn-circle btn-circle-sm m-1"
          >
            <i className="fab fa-instagram" />
          </a>
          <a
            href="https://www.facebook.com/ilusionist.pierre/"
            className="btn btn-primary btn-circle btn-circle-sm m-1"
          >
            <i className="fab fa-facebook-f" />
          </a>
        </div>
      </div>

      <div className="footer-copyright text-center py-3">
        © {year} Copyright:{" "}
        <a
          href="https://pierrevelardemagic.com/"
          style={{ color: "#FFF", textDecoration: "underline" }}
        >
          pierrevelardemagic.com
        </a>
      </div>
    </footer>
  );
}
