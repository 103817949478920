import React from 'react';
import ReactDOM from 'react-dom';
import Form from "./Form";

function Modal(props) {
    return (
        ReactDOM.createPortal(
            <div className="modal fade contacto" tabIndex="-1" role="dialog" aria-labelledby="contactoLabel"
                 aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{background: "#333"}}>
                        <Form handleSwal={props.handleSwal}/>
                    </div>
                </div>
            </div>,
            document.getElementById('modal')
        )
    )
}

export default Modal;