import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import productData from "../data/products";
import "./styles/Shop.css";

const img1 = "https://pierrevelarde.s3.amazonaws.com/products/64700-full.png";
const videoSrc =
  "https://pierrevelarde.s3.amazonaws.com/products/spectro_touch.mp4";

class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: img1,
      video: false,
      btnVideo: videoSrc,
    };
  }

  handleImgClick = (src, video) => {
    if (video) {
      this.setState({
        img: src,
        video: false,
        btnVideo: video,
      });
    } else {
      this.setState({
        img: src,
        video: false,
        btnVideo: false,
      });
    }
  };

  handleVideoClick = () => {
    this.setState({
      video: this.state.btnVideo,
      btnVideo: false,
    });
  };

  render() {
    return (
      <>
        <Navbar />

        <div id="about" className="nano" style={{ zIndex: "2" }}>
          {/* <div className="nano-content"> */}
            <div className="row" style={{ margin: 0 }}>
              <div id="about" className="nano" style={{ zIndex: "2"  }}>
                {/* <div className="nano-content"> */}
                  <div className="row" style={{ margin: 20, marginTop: 100 }}>
                    {Object.values(productData).map((product, index) => (
                      <div key={index} className="col-lg-4 col-md-6 mb-4 ">
                        <div
                          className="card"
                          style={{ background: "#333", color: "#FFF" }}
                        >
                          <img
                            className="img-fluid"
                            src={product.img1}
                            alt={product.name}
                            onClick={() => {
                              this.handleImgClick(
                                product.img1,
                                product.videoSrc
                              );
                            }}
                            style={{ width: "100%" }}
                          />
                          <div className="card-body">
                            <h4
                              className="card-title"
                              style={{ color: "#FFF" }}
                            >
                              {product.name}
                            </h4>
                            {/* <h5>{product.price}</h5> */}

                        <Link
                          to={`/product?id=${index + 1}`}
                          className="btn btn-outline-light"
                        >
                          Ver Más
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <Footer />
              {/* </div> */}
            </div>
          </div>
          {/* </div> */}
        </div>
      </>
    );
  }
}

export default Shop;
