import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import productData from "../data/products";
import "./styles/Product.css";

class Product extends React.Component {
  constructor({ location }) {
    super();
    const searchParams = new URLSearchParams(location.search);
    const productId = searchParams.get("id");
    this.state = {
      name: productData[productId].name,
      img: productData[productId].img1,
      img1: productData[productId].img1,
      img2: productData[productId].img2,
      videoSrc: productData[productId].videoSrc,
      video: false,
      btnVideo: productData[productId].videoSrc,
      description: productData[productId].description,
      price: productData[productId].price,
    };
  }

  handleImgClick = (src, video) => {
    this.setState({
      img: src,
      video: false,
      btnVideo: video ? video : false,
    });
  };

  handleVideoClick = () => {
    this.setState({
      video: this.state.btnVideo,
      btnVideo: false,
    });
  };

  render() {
    const descriptionLines = this.state.description.split("\n");
    return (
      <>
        <Navbar />

        <div id="about" className="nano" style={{zIndex: "2" }}>
          {/* <div className="nano-content"> */}
            <div
              className="row"
              style={{ background: "#000", padding: "50px", margin: 0 }}
            >
              <div
                className="col-12"
                style={{ padding: "20px", color: "#FFF" }}
              >
                <h1 className="text-center">{this.state.name}</h1>
              </div>

              <div className="col-lg-6 col-md-12">
                <div className="card" style={{ background: "#333" }}>
                  {this.state.video && (
                    <video className="video-fluid card-img-top" autoPlay loop>
                      <source src={this.state.video} type="video/mp4" />
                    </video>
                  )}
                  {!this.state.video && (
                    <img
                      className="img-fluid card-img-top"
                      src={this.state.img}
                    />
                  )}
                  {this.state.btnVideo && (
                    <button
                      type="button"
                      className="btn btn-dark centered"
                      onClick={this.handleVideoClick}
                      style={{ "z-index": "1" }}
                    >
                      <i className="fas fa-play-circle fa-3x" />
                    </button>
                  )}
                  <div className="card-body" style={{ padding: "5px" }}>
                    <div
                      id="recipeCarousel"
                      className="carousel slide w-100"
                      data-ride="carousel"
                      data-interval="false"
                    >
                      <div className="carousel-inner w-100" role="listbox">
                        <div className="carousel-item row no-gutters active d-flex justify-content-center">
                          <div className="col-6 float-left d-flex justify-content-center">
                            <img
                              className="img-fluid"
                              src={this.state.img1}
                              video={this.state.videoSrc}
                              onClick={() => {
                                this.handleImgClick(
                                  this.state.img1,
                                  this.state.videoSrc
                                );
                              }}
                            />
                          </div>
                          <div className="col-6 float-left d-flex justify-content-center">
                            <img
                              className="img-fluid"
                              src={this.state.img2}
                              onClick={() => {
                                this.handleImgClick(this.state.img2, false);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <a
                        className="carousel-control-prev"
                        href="#recipeCarousel"
                        role="button"
                        data-slide="prev"
                        style={{ paddingRight: "20px" }}
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Anterior</span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#recipeCarousel"
                        role="button"
                        data-slide="next"
                        style={{ paddingLeft: "20px" }}
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        />
                        <span className="sr-only">Siguiente</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 col-md-12 align-middle"
                style={{ color: "#FFF" }}
              >
                <p>
                  {descriptionLines.map((line, index) => (
                    <>
                      {line}
                      <br />
                    </>
                  ))}
                </p>
                {/* <h4> {this.state.price}</h4> */}
                <button
                  type="button"
                  className="btn btn-outline-light"
                  data-toggle="modal"
                  data-target=".contacto"
                >
                  CONTÁCTANOS
                </button>
              </div>
            </div>

            <Footer />
          {/* </div> */}
        </div>
      </>
    );
  }
}

export default Product;
