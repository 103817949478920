import React from 'react';
import Api from '../api';

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {}
        }
    }

    handleChange = (event) => {
        this.setState({
            form: {
                ...this.state.form,
                [event.target.name]: event.target.value
            }
        })
    }

    handleClick = async () => {
        const {name, email, msg} = this.state.form;
        if(name && email && msg) {
            try {
                let result = await Api.send(this.state.form);
                if (result.msg === 'ok') {
                    const $ = window.$;
                    $('.contacto').modal('hide');
                    this.props.handleSwal(1);
                } else {
                    this.props.handleSwal(2);
                }
            } catch (e) {
                this.props.handleSwal(2);
            }
        }else{
            this.props.handleSwal(3);
        }
    }

    render() {
        return (
            <>
                <div className="modal-header">
                    <h5 className="modal-title" id="contactoLabel" style={{color: "#FFF"}}>Contacto</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                            style={{color: "#FFF"}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form>
                        <div className="form-group">
                            <label style={{color: "#FFF"}}>Nombre / Empresa: *</label>
                            <input name="name" type="text" className="form-control" required
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label style={{color: "#FFF"}}>Teléfono:</label>
                            <input name="phone" type="text" className="form-control" onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label style={{color: "#FFF"}}>Email: *</label>
                            <input name="email" type="email" className="form-control" required
                                   onChange={this.handleChange}/>
                        </div>
                        <div className="form-group">
                            <label style={{color: "#FFF"}}>Mensaje: *</label>
                            <textarea name="msg" className="form-control" rows="4" required
                                      onChange={this.handleChange}/>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-outline-light mr-auto"
                            data-dismiss="modal">Cerrar
                    </button>
                    <button type="button" className="btn btn-outline-light" onClick={this.handleClick}>Enviar</button>
                </div>
                {/*<Swal show={this.state.swal} title={this.state.swalTitle} text={this.state.swalMsg} type={this.state.swalType}/>*/}

                {/*<SweetAlert
                    show={this.state.swal}
                    title={this.state.swalTitle}
                    text={this.state.swalMsg}
                    type={this.state.swalType}
                    confirmButtonText="OK"
                />*/}
            </>
        )
    }
}

export default Form;