import React from 'react';
import {Link} from 'react-router-dom';
import Modal from "./Modal";
import SweetAlert from "react-bootstrap-sweetalert";

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            swalType: 0
        }
    }

    handleSwal = (type) => {
        this.setState({swalType: type});
    }

    onConfirm = () => {
        this.setState({swalType: 0});
    }

    render() {
        return (
            <>
                {this.state.swalType === 1 &&
                <SweetAlert
                    success
                    title="Mensaje Enviado"
                    onConfirm={this.onConfirm}>

                    Nos pondremos en contacto contigo a la brevedad

                </SweetAlert>
                }
                {this.state.swalType === 2 &&
                <SweetAlert
                    error
                    title="Error"
                    onConfirm={this.onConfirm}>

                    No pudimos enviar tu mensaje, vuelve a intentarlo en unos minutos

                </SweetAlert>
                }
                {this.state.swalType === 3 &&
                <SweetAlert
                    info
                    title="Error"
                    onConfirm={this.onConfirm}>

                    Debes completar todos los campos obligatorios (*)

                </SweetAlert>
                }
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark navbar-gradient">
                    <div className="container col-12 text-right">

                        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                                data-target="#navbar10">
                            <span className="navbar-toggler-icon"/>
                        </button>
                        <div className="navbar-collapse collapse" id="navbar10">
                            <ul className="navbar-nav nav-fill w-100">
                                <li className="nav-item">
                                    <Link className="nav-link" to="../">INICIO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="../bio">BIO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="../shop">SHOP</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#" data-toggle="modal"
                                          data-target=".contacto">CONTACTO</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <Modal handleSwal={this.handleSwal}/>
            </>
        )
    }
}

export default Navbar;

/*export default function Navbar() {
    return (
        <>
            <SweetAlert
                success
                title="Good job!">
                You clicked the button!
            </SweetAlert>
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark navbar-gradient">
                <div className="container col-12 text-right">

                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                            data-target="#navbar10">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="navbar-collapse collapse" id="navbar10">
                        <ul className="navbar-nav nav-fill w-100">
                            <li className="nav-item">
                                <Link className="nav-link" to="../">INICIO</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="../bio">BIO</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="../shop">SHOP</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="#" data-toggle="modal"
                                      data-target=".contacto">CONTACTO</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Modal/>
        </>
    )
}*/
