const BASE_URL = "https://www.pierrevelardemagic.com";

async function callApi(endpoint, options = {}) {

    options.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    };

    let url = BASE_URL + endpoint;

    const response = await fetch(url, options);
    return await response.json();
}

const index = {
    send(form) {
        return callApi('/contacto', {
            method: 'POST',
            body: JSON.stringify(form),
        });
    },

    validatePassword(form) {
        return callApi('/secure-area', {
            method: 'POST',
            body: JSON.stringify(form),
        });
    }
};

export default index;
