import Inicio from "./pages/Inicio";
import Bio from "./pages/Bio";
import Shop from "./pages/Shop";
import SecretArea from "./pages/SecretArea";
import Product from "./pages/Product";

const appRoutes = [
  {
    path: "/",
    name: "INICIO",
    component: Inicio
  },
  {
    path: "/bio",
    name: "BIO",
    component: Bio
  },
  {
    path: "/shop",
    name: "SHOP",
    component: Shop,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/onlineinstruction",
    name: "Genious Pad",
    component: SecretArea
  }
];

export default appRoutes;
