import React from 'react';

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

class NotFound extends React.Component {
    render() {
        return(
            <>
            <Navbar />
            <div style={{height: "90vh", position: "relative", paddingTop: "20%"}}>
            <h1 className="text-center text-white">NotFound</h1>
            </div>
            <Footer />
            </>
        )
    }
}

export default NotFound;