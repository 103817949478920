import React, { useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import Api from "../api";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import SweetAlert from "react-bootstrap-sweetalert";
import "./styles/SecretArea.css";

const SecretArea = () => {
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [status, setStatus] = useState(false);
  const [swalError, setSwalError] = useState(false);

  const validatePassword = async () => {
    try {
      let result = await Api.validatePassword({
        password,
        token,
      });
      if (result.msg === "ok" && result.data) {
        setStatus(true);
      } else {
        setSwalError(true);
        setPassword("");
      }
    } catch (e) {
      setSwalError(true);
      setPassword("");
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleRecaptcha = async (e) => {
    setToken(e);
  };

  return (
    <>
      <Navbar />

      {swalError && (
        <SweetAlert
          info
          title="Error"
          onConfirm={() => {
            setSwalError(false);
          }}
        >
          Incorrect password
        </SweetAlert>
      )}

      <div id="about" className="nano" style={{ position: "absolute" }}>
        <div className="nano-content">
          {!status && (
            <div
              className="row"
              style={{
                background: "#000",
                padding: "30px",
                margin: 0,
                height: "90vh",
              }}
            >
              <div className="col-md-4 d-xs-none d-sm-none d-md-block"></div>

              <div
                className="col-md-4 col-sm-12 text-center"
                style={{ top: "20%" }}
              >
                <h1 className="text-white" style={{ marginBottom: "32px" }}>
                  Secret Area
                </h1>
                <input
                  type="password"
                  value={password}
                  onChange={handlePassword}
                  placeholder="password"
                  style={{ fontSize: "24px" }}
                />
                <br />
                <br />
                <GoogleReCaptchaProvider reCaptchaKey="6LegCiQpAAAAAH2zKn_oN8GvdzW5Ipo63wGA2ClI">
                  <GoogleReCaptcha onVerify={handleRecaptcha} />
                </GoogleReCaptchaProvider>
                <button
                  type="button"
                  className="btn btn-outline-light btn-lg"
                  onClick={validatePassword}
                >
                  Enter
                </button>
              </div>

              <div className="col-md-4 d-xs-none d-sm-none d-md-block"></div>
            </div>
          )}
          {status && (
            <div
              className="row"
              style={{
                background: "#000",
                padding: "30px",
                margin: 0,
                height: "90vh",
              }}
            >
              <div
                className="col-12"
                style={{ padding: "20px", color: "#FFF" }}
              >
                <h1 className="text-center">GENIOUS PAD</h1>
              </div>

              <div className="col-md-2 d-xs-none d-sm-none d-md-block"></div>

              <div className="col-md-8 col-sm-12">
                <video
                  className="video-fluid card-img-top"
                  autoPlay
                  loop
                  controls
                  controlsList="nodownload"
                  onContextMenu={(e) => e.preventDefault()}
                >
                  <source
                    src={`./secure-area/video/geniouspad.mp4?p=${password}`}
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="col-md-2 d-xs-none d-sm-none d-md-block"></div>
            </div>
          )}

          <Footer />
        </div>
      </div>
    </>
  );
};

export default SecretArea;
