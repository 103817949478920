import React from 'react';
import './styles/Bio.css';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import videoSrc from '../assets/img/video.mp4';

class Bio extends React.Component {
    render() {
        return (
            <>
                <video autoPlay muted loop id="myVideo">
                    <source src={videoSrc} type="video/mp4"/>
                </video>

                <Navbar/>

                <div id="about" className="nano" style={{zIndex: "2" }}>
                    {/* <div className="nano-content"> */}
                        <div className="content">
                            <div className="col-12 text-center" style={{paddingTop: "25vw"}}>
                                <h1 className="align-self-center text-light titulo"
                                    style={{paddingBottom: "20px", fontFamily: ['Playfair Display', 'serif']}}>PIERRE
                                    ILUSIONISTA</h1>
                                <p className="text-center" style={{paddingLeft: "50px", paddingRight: "50px"}}>Pierre es
                                    un Mago - Ilusionista Campeón y creador de magia original.<br/>
                                    Desarrolló un gran interés por la Magia desde muy temprana edad, estudiando y
                                    perfeccionado el místico arte de la Magia con el paso de los años; lo que le ha
                                    hecho acreededor a múltiples reconocimientos y premios.</p>
                            </div>
                            <div className="row h-100 justify-content-center align-items-center">
                                <div className="v-divider">
                                </div>
                            </div>
                            <div className="col-12 text-center">
                                <p className="text-center"
                                   style={{paddingLeft: "50px", paddingRight: "50px", marginTop: "16px"}}>Con más de una
                                    década
                                    de experiencia en shows de magia e ilusionismo personalizados para cada
                                    ocación.<br/>
                                    Pierre ha realizado innumerables presentaciones en teatros a nivel nacional e
                                    internacional, así como también, en conocidos programas de televisión.<br/>
                                    Actualmente sus creaciones se venden en más de 80 paises, aportando conocimiento en
                                    el místico Arte de la Magia
                                </p>
                            </div>
                        </div>

                        <div className="back-gradient">

                        </div>

                        <Footer/>

                    {/* </div> */}
                </div>
            </>
        )
    }
}

export default Bio;