import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import appRoutes from "../routes";

import NotFound from "../pages/NotFound";

function Index() {
    return (
        <BrowserRouter>
            <Switch>
                {appRoutes.map((prop, key) => {
                    return (
                        <Route
                            key={key}
                            exact path={prop.path}
                            component={prop.component}
                            name={prop.name}
                        />
                    );
                })}
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    );
}

export default Index;
